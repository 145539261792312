import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CenterService} from './center.service';
import {StorageService} from './storage.service';
import {Helper} from '../constants/helper';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})

export class NavService {
  user: any;
  userData: any;

  constructor(private centerService: CenterService) {
    this.onResize();
    this.user = StorageService.getItem('self');
    this.getUserDetail();
    if (this.screenWidth < 991) {
      this.collapseSidebarMainMenu = false;
      this.collapseSidebarSecondMenu = true;
    }
  }

  public openclass: boolean = false;
  public screenWidth: any;

  public collapseSidebarMainMenu = false;
  public collapseSidebarSecondMenu = false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth >= 991;

  public fullScreen = false;

  MENUITEMS: Menu[] = [
    {
      title: 'General', path: '/general/home', icon: 'pe-7s-home', type: 'sub', active: true, children:
        [
          {
            title: 'General', type: 'sub', active: true, children: [
              {path: '/general/home', title: 'Home', type: 'link'}
            ]
          }
        ],
    },
    {
      title: 'Branches', path: '/branches/home', icon: 'fas fa-code-branch', type: 'sub', active: false, children:
        [
          {
            title: 'Branches', type: 'sub', active: true, children: [
              {path: '/branches/home', title: 'Home', type: 'link'},
              {path: '/branches/add', title: 'Add Branch', type: 'link'},
              {path: '/branches/list', title: 'List Branches', type: 'link'},
              {path: '/branches/share-percentage', title: 'Share Percentage', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Students', path: '/students/home', icon: 'fas fa-user-graduate', type: 'sub', active: false, children:
        [
          {
            title: 'Student', type: 'sub', active: true, children: [
              {path: '/students/home', title: 'Home', type: 'link'},
              {path: '/students/add-student', title: 'Add Student', type: 'link'},
              {path: '/students/re-register-student', title: 'Re - Register Students', type: 'link'},
              {path: '/students/student-bulk-upload', title: 'Student Bulk Upload', type: 'link'},
              {path: '/students/list-student', title: 'List Students', type: 'link'},
              {path: '/students/branch-student', title: 'Branch Students', type: 'link'},
              {path: '/students/re-register-branch-student', title: 'Re - Register Branch Students', type: 'link'},
              {path: '/students/transferred-student', title: 'Transferred Students', type: 'link'},
              {path: '/students/transferred-branch-student', title: 'Transferred Branch Students', type: 'link'},
              /*{path: '/students/list-re-registered-student', title: 'List Re-Registered Students', type: 'link'}*/
              // {path: '/students/re-register-student', title: 'Re-Register Students', type: 'link'},
              // {path: '/students/transfer-students', title: 'Transfer Students', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Exams', path: '/exams/home', icon: 'pe-7s-note2', type: 'sub', active: false, children:
        [
          {
            title: 'Exams', type: 'sub', active: true, children: [
              {path: '/exams/home', title: 'Home', type: 'link'},
              {path: '/exams/exam-time-table', title: 'Exam Time Table', type: 'link'},
              {path: '/exams/mark-back-exam', title: 'Mark Back Exams', type: 'link'},
              {path: '/exams/branch-marked-back-exam', title: 'Branch Marked Back Exams', type: 'link'},
              {path: '/exams/exam-appeared-students', title: 'Exam Appeared Students', type: 'link'},
              {path: '/exams/exam-appeared-branch-students', title: 'Exam Appeared Branch Students', type: 'link'},
              /*{path: '/exams/practical-marks', title: 'Practical Marks', type: 'link'},
              {path: '/exams/ia-aw-marks', title: 'IA-AW Marks', type: 'link'}*/
            ]
          }
        ],
    },
    /*{
      title: 'Results', path: '/results/home', icon: 'pe-7s-study', type: 'sub', active: false, children:
        [
          {
            title: 'Results', type: 'sub', active: true, children: [
              {path: '/results/home', title: 'Home', type: 'link'},
              {path: '/results/student-result', title: 'Students\' Result', type: 'link'}
            ]
          }
        ],
    },*/
    {
      title: 'Accounts', path: '/accounts/home', icon: 'fas fa-credit-card', type: 'sub', active: false, children:
        [
          {
            title: 'Accounts', type: 'sub', active: true, children: [
              {path: '/accounts/home', title: 'Home', type: 'link'},
              {path: '/accounts/receipts', title: 'Receipts', type: 'link'},
              {path: '/accounts/transactions', title: 'Transactions', type: 'link'},
              {path: '/accounts/process-student', title: 'Process Student', type: 'link'},
              {path: '/accounts/add-money', title: 'Add Money', type: 'link'},
              {path: '/accounts/branch-reports', title: 'Branch Report', type: 'link'},
              {path: '/accounts/branch-process-students', title: 'Branch Process Students', type: 'link'},
              {path: '/accounts/branch-receipts', title: 'Branch Receipts', type: 'link'},
              {path: '/accounts/branch-transactions', title: 'Branch Transactions', type: 'link'},
            ]
          }
        ],
    },
    /*{
      title: 'Exams', path: '/exams/home', icon: 'pe-7s-note2', type: 'sub', active: false, children:
        [
          {
            title: 'Exam', type: 'sub', active: true, children: [
              {path: '/exam/home', title: 'Home', type: 'link'},
              {path: '/exam/datesheet', title: 'Datesheet', type: 'link'}
            ]
          }
        ],
    },*/
    {
      title: 'Support', path: '/support/home', icon: 'pe-7s-headphones', type: 'sub', active: false, children:
        [
          {
            title: 'Support', type: 'sub', active: true, children: [
              {path: '/support/home', title: 'Home', type: 'link'},
              {path: '/support/admission-tickets', title: 'Admission Tickets', type: 'link'},
              {path: '/support/re-registration-tickets', title: 'Re-Registration Tickets', type: 'link'},
              {path: '/support/examination-tickets', title: 'Examination Tickets', type: 'link'},
              {path: '/support/marksheet-tickets', title: 'Marksheet Tickets', type: 'link'},
              {path: '/support/transcript-tickets', title: 'Transcript Tickets', type: 'link'},
              {path: '/support/revaluation-tickets', title: 'Revalution Tickets', type: 'link'},
              {path: '/support/migration-tickets', title: 'Migration Tickets', type: 'link'},
              {path: '/support/original-degree-tickets', title: 'Original Degree Tickets', type: 'link'},
              {path: '/support/e-learning-tickets', title: 'E-Learning Tickets', type: 'link'},
              {path: '/support/other-tickets', title: 'Other Tickets', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Docs', path: '/docs/home', icon: 'pe-7s-study', type: 'sub', active: false, children:
        [
          {
            title: 'Docs', type: 'sub', active: true, children: [
              {path: '/docs/home', title: 'Home', type: 'link'},
              {path: '/docs/marksheet', title: 'Marksheet', type: 'link'},
              {path: '/docs/branch-marksheet', title: 'Branch Marksheet', type: 'link'},
              {path: '/docs/cmm', title: 'CMM', type: 'link'},
              {path: '/docs/branch-cmm', title: 'Branch CMM', type: 'link'},
              {path: '/docs/original-degree', title: 'Original Degree', type: 'link'},
              {path: '/docs/branch-original-degree', title: 'Branch Original Degree', type: 'link'},
              {path: '/docs/provisional', title: 'Provisional', type: 'link'},
              {path: '/docs/branch-provisional', title: 'Branch Provisional', type: 'link'},
              {path: '/docs/applied-docs', title: 'Applied Docs', type: 'link'},
              {path: '/docs/branch-applied-docs', title: 'Branch Applied Docs', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Notify', path: '/notify/home', icon: 'fas fa-bell', type: 'sub', active: false, children:
        [
          {
            title: 'Notify', type: 'sub', active: true, children: [
              {path: '/notify/home', title: 'Home', type: 'link'},
              {path: '/notify/add', title: 'Notify Branches', type: 'link'},
            ]
          }
        ],
    },
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  getUserDetail() {
    this.centerService.detail(this.user.user_id).subscribe(data => {
      if (data) {
        this.userData = data;
        let hiddenMenus = [
          {
            'menu': 'BRANCHES',
            'hideMenu': true,
            'submenus': ['HOME', 'ADD BRANCH', 'LIST BRANCHES', 'SHARE PERCENTAGE']
          },
          {
            'menu': 'STUDENTS',
            'hideMenu': false,
            'submenus': ['BRANCH STUDENTS', 'STUDENT BULK UPLOAD', 'RE - REGISTER BRANCH STUDENTS', 'TRANSFERRED BRANCH STUDENTS']
          },
          {
            'menu': 'DOCS',
            'hideMenu': false,
            'submenus': ['BRANCH MARKSHEET','BRANCH CMM', 'BRANCH ORIGINAL DEGREE', 'BRANCH PROVISIONAL','BRANCH APPLIED DOCS']
          },
          {
            'menu': 'EXAMS',
            'hideMenu': false,
            'submenus': ['BRANCH MARKED BACK EXAMS', 'EXAM APPEARED BRANCH STUDENTS']
          },
          {
            'menu': 'ACCOUNTS',
            'hideMenu': false,
            'submenus': ['ADD MONEY', 'BRANCH REPORT', 'BRANCH PROCESS STUDENTS', 'BRANCH RECEIPTS', 'BRANCH TRANSACTIONS']
          },
          {
            'menu': 'NOTIFY',
            'hideMenu': false,
            'submenus': ['NOTIFY BRANCHES']
          }
        ];
        this.MENUITEMS.forEach(menu => {
          if (hiddenMenus && hiddenMenus.length > 0) {
            hiddenMenus.forEach(dtMenu => {
              if (menu && (menu.title.toUpperCase() === dtMenu.menu)) {
                if (dtMenu.menu) {
                  menu['is_hidden'] = (dtMenu.hideMenu && this.userData['adding_branch'] === 'DISALLOW');
                  if (menu['children'] && menu['children'].length > 0 && menu['children'][0]) {
                    if (menu['children'][0] && menu['children'][0]['children'] && menu['children'][0] && menu['children'][0]['children'].length > 0) {
                      menu['children'][0]['children'].forEach(child => {
                        if (Helper.inArray(child.title.toUpperCase(), dtMenu.submenus)) {
                          if (child && child['title'] === 'Student Bulk Upload') {
                            child['is_hidden'] = (this.userData['bulk_upload'] === 'DISALLOW');
                          } else if (child && child['title'] === 'Add Branch') {
                            child['is_hidden'] = (this.userData['center_adding_branch'] === 'DISALLOW');
                          } else {
                            child['is_hidden'] = (this.userData['adding_branch'] === 'DISALLOW');
                          }
                        }
                      });
                    }
                  }
                }
              }
            });
          }
        });
      }
    });
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
